import {
  IonGrid,
  IonRow,
} from "@ionic/react";
import { useState } from "react";
import { bikeCollections } from "./BikeCollections";
import "./Bikes.css";
import BookingSlot from "./BookingSlot";

const Bikes: React.FC = () => {
  const [bikes] = useState([...bikeCollections]);
  return (
    <div className="bikes-display-section">
      <div className="caption">
        <h1>Bike Rentals in Vizag</h1>
        <address title="Address: Shop No.1, The Royal Palace, Beside Kalimata Mandir,  Near To Gate No.1, Railway Station, Visakhapatnam, Andhra Pradesh">Address: Shop No.1, The Royal Palace, Beside Kalimata Mandir,  Near To Gate No.1, Railway Station, Visakhapatnam, Andhra Pradesh</address>
      </div>
      <h2 className="section-caption">Our Fleet</h2>
      <IonGrid>
        <IonRow>
          {bikes.map((bike, index) => (
            <BookingSlot bike={bike} index={index} key={bike.name} />
          ))}
        </IonRow>
      </IonGrid>
      {/* <Findus /> */}
      <div className="caption" style={{width: "80%", margin: "auto"}}>
        <h1>About us</h1>
        <p>Welcome to Mr Bike Rentals Vizag, your ultimate destination for all your biking needs in Visakhapatnam! we are located near Vizag Railway Station, we offer a wide range of high-quality bikes and scooters for rent at affordable rates. If you're searching for bike rentals in Vizag, or bike hire in Vizag, Mr Bike Rentals Vizag is your go-to destination. Whether you're a tourist exploring the beautiful city of Vizag or a local resident in need of a reliable mode of transportation,  Our extensive fleet includes a variety of bikes to suit your preferences and budget. we pride ourselves on providing exceptional customer service and ensuring a hassle-free rental experience. Contact us today to reserve your bike and embark on your Vizag adventure!</p>
      </div>
      <br />
    </div>
  );
};

export default Bikes;
