import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonCheckbox,
  IonTitle,
  IonToolbar,
  IonInput,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core/components";
import { useEffect, useRef, useState } from "react";

import "./BookingSlot.css";
import moment from "moment";

interface IBike {
  bike: any;
  index: number;
}

export const BookingSlot: React.FC<IBike> = ({ bike, index }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [message, setMessage] = useState("");

  const [state, setState] = useState({
    startDate: new Date().toISOString().split("T")[0],
    startTime: "07:00 am",
    endDate: new Date().toISOString().split("T")[0],
    endTime: "07:00 pm",
    numberOfVehicles: 1,
    tripType: "",
    customerName: "",
    nativePlace: "",
    licence: false
  });

  function confirm() {
    if (
      state.licence &&
      state.customerName &&
      state.nativePlace &&
      state.startDate &&
      state.startTime &&
      state.endDate &&
      state.endTime &&
      state.tripType &&
      state.numberOfVehicles
    ) {
      let url = "https://wa.me/+919155405060";
      let text = `*Name:*  *_${state.customerName}_*\n\n*Native Place:*  *_${state.nativePlace}_*\n\n*Licence:* ${state.licence ? "Yes" : "No"}\n\n*Model:*  *_${bike.name}_*\n\n*No. Of Vehicles:*  ${state.numberOfVehicles
        }\n\n*Pickup Date:* ${moment(state.startDate).format("DD-MM-yyyy")
        } : ${state.startTime}\n\n*Dropoff Date:* ${moment(state.endDate).format("DD-MM-yyyy")
        } : ${state.endTime}\n\n*Trip:* ${state.tripType}\n\n\n_MrBikes.in_`;
      window.location.href = url + `/?text=${encodeURIComponent(text)}`;
      modal.current?.dismiss();
    } else {
      setMessage("Please fill all the fields");
    }
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === "confirm") {
      setMessage(`Hello, ${ev.detail.data}!`);
    }
  }
  const handledChange = (value: any, name: any) => {
    setMessage("");
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const options = (
    <>
      <option value="06:00 am">06:00 am</option>
      <option value="07:00 am">07:00 am</option>
      <option value="08:00 am">08:00 am</option>
      <option value="09:00 am">09:00 am</option>
      <option value="10:00 am">10:00 am</option>
      <option value="11:00 am">11:00 am</option>
      <option value="12:00 pm">12:00 pm</option>
      <option value="01:00 pm">01:00 pm</option>
      <option value="02:00 pm">02:00 pm</option>
      <option value="03:00 pm">03:00 pm</option>
      <option value="04:00 pm">04:00 pm</option>
      <option value="05:00 pm">05:00 pm</option>
      <option value="06:00 pm">06:00 pm</option>
      <option value="07:00 pm">07:00 pm</option>
      <option value="08:00 pm">08:00 pm</option>
    </>
  );

  const disableIfNoLicence = !state.licence;

  return (
    <IonCol sizeMd="3" sizeSm="12" sizeXs="12" key={index}>
      <IonCard>
        <IonCardHeader>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="bikes"
              width={300}
              height={175}
              src={bike.image}
              style={{ backgroundColor: "white" }}
            />
          </div>
          <IonCardTitle>{bike.name}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          {bike.priceDetails.map((priceItem: any, idx: any) => (
            <IonRow
              className={idx === 0 ? "td-row-first-row" : "td-row"}
              key={JSON.stringify(priceItem)}
            >
              <IonCol className="add-border-right" col-3>
                <p>{priceItem.duration}</p>
              </IonCol>
              <IonCol className="add-border-right" col-3>
                <p style={{ textAlign: "right" }}>{priceItem?.kms}</p>
              </IonCol>
              <IonCol col-3>
                <p style={{ textAlign: "right" }}>{priceItem.price} /-</p>
              </IonCol>
            </IonRow>
          ))}
        </IonCardContent>
        <IonButton
          expand="block"
          shape="round"
          fill="solid"
          style={{ textTransfor: "capitalize" }}
          id={"open-modal" + index}
        >
          Book Now
        </IonButton>
        <IonModal
          ref={modal}
          trigger={"open-modal" + index}
          onWillDismiss={(ev) => onWillDismiss(ev)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Model: &nbsp; {bike.name}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            
            <IonItem>
              <IonLabel>Do You Have Driving Licence ?</IonLabel>
              <IonCheckbox  onIonChange={(e) => handledChange(e.target.checked, "licence")} id="licence" slot="end"></IonCheckbox>
            </IonItem>
            {message && (
              <IonItem >
                <IonLabel style={{ color: "red" }}>{message}</IonLabel>
              </IonItem>
            )}
            <IonItem>
              <IonLabel>Name :</IonLabel>
              <IonInput disabled={disableIfNoLicence} id="customerName" placeholder="Enter As per Aadhaar" onIonInput={(e) => handledChange(e?.target?.value, "customerName")}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Native Place:</IonLabel>
              <IonInput disabled={disableIfNoLicence} onIonInput={(e) => handledChange(e?.target?.value, "nativePlace")} placeholder="Enter Your Home Town" id="nativePlace"></IonInput>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonLabel>Pickup Date &nbsp;</IonLabel>
              <div className="clock-block">
                <input
                  onChange={(e) => handledChange(e?.target?.value, "startDate")}
                  value={state.startDate || new Date().toISOString().split("T")[0]}
                  type="date"
                  id="startDate"
                  style={{ textAlign: "left" }}
                  placeholder="dd-mm-yyyy"
                  disabled={disableIfNoLicence}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonLabel>Pickup Time &nbsp;</IonLabel>
              <div className="clock-select">
                <select
                  id="startTime"
                  value={state.startTime}
                  onChange={(e) => handledChange(e?.target?.value, "startTime")}
                >
                  {options}
                </select>
              </div>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonLabel>Dropoff Date &nbsp;</IonLabel>
              <div className="clock-block">
                <input
                  onChange={(e) => handledChange(e?.target?.value, "endDate")}
                  value={state.endDate || new Date().toISOString().split("T")[0]}
                  type="date"
                  id="endDate"
                  min={state.startDate}
                  disabled={disableIfNoLicence}
                  placeholder="dd-mm-yyyy"
                />
              </div>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonLabel>Dropoff Time &nbsp;</IonLabel>
              <div className="clock-select">
                <select
                  id="endTime"
                  value={state.endTime}
                  onChange={(e) => handledChange(e?.target?.value, "endTime")}
                >
                  {options}
                </select>
              </div>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonLabel>No. Of Vehicles: &nbsp;</IonLabel>
              <div className="clock-select">
                <select
                  value={state.numberOfVehicles}
                  disabled={disableIfNoLicence}
                  onChange={(e) =>
                    handledChange(e.target.value, "numberOfVehicles")
                  }
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </div>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonRadioGroup
                value={state.tripType}
                onIonChange={(e) => {
                  handledChange(e.detail.value, "tripType");
                }}
              >
                <IonListHeader style={{ paddingLeft: 0 }}>
                  <IonLabel>Trip: &nbsp;</IonLabel>
                </IonListHeader>

                <IonItem>
                  <IonLabel>Within City (30km Radius)</IonLabel>
                  <IonRadio disabled={disableIfNoLicence} slot="start" value="Within City" />
                </IonItem>

                <IonItem >
                  <IonLabel>Outoff City (Araku, Lambasingi, etc... )</IonLabel>
                  <IonRadio disabled={disableIfNoLicence} slot="start" value="Outoff City" />
                </IonItem>
              </IonRadioGroup>
            </IonItem>
            <IonItem disabled={disableIfNoLicence}>
              <IonLabel style={{ color: "red" }}>Note: {bike.exceed}</IonLabel>
            </IonItem>
          </IonContent>
          <IonFooter>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => modal.current?.dismiss()}>
                  Cancel
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton
                  shape="round"
                  strong={true}
                  disabled={disableIfNoLicence}
                  style={{ color: "#ff350f" }}
                  onClick={() => confirm()}
                >
                  Confirm
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </IonCard>
    </IonCol>
  );
};

export default BookingSlot;
