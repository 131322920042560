export const bikeCollections = [
  {
    id: 1,
    name: "Hero Pleasure",
    image: "assets/bikes/hero-plusure.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "2,799",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "4,899",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "6,299",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "6,999",
        kms: "2,000 Kms",
      },
    ],
    exceed: 'Kilometors limit if exceeds Rs.3/KM'
  },
  {
    id: 2,
    name: "Honda Dio 5G",
    image: "assets/bikes/Dio-min_2.png",
    exceed: 'Kilometors limit if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "2,799",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "4,899",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "6,299",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "6,999",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 3,
    name: "Honda Activa 5G",
    image: "assets/bikes/activaa-5g.jpeg",
    exceed: 'Kilometors limit if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "2,799",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "4,899",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "6,299",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "6,999",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 4,
    name: "Honda Aviator 5G",
    image: "assets/bikes/Aviator.png",
    exceed: 'Kilometors limit if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "2,799",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "4,899",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "6,299",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "6,999",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 5,
    name: "TVS Jupiter BS6",
    image: "assets/bikes/TVS-Jupiter-new-bs6.jpg",
    exceed: 'Kilometors limit if exceeds Rs.4/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: "599", kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "3,500",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "5,600",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "7,350",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "8,400",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 6,
    name: "Honda Activa 6G",
    exceed: 'Kilometors limit if exceeds Rs.4/KM',
    image: "assets/bikes/Honda_activa_6g.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "3,500",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "5,600",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "7,350",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "8,400",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 55,
    name: "Suzuki Access 125cc",
    image: "assets/bikes/Access-125cc.jpg",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "4,199",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "6,999",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "8,399",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "9,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 56,
    name: "Suzuki Avenis 125cc",
    image: "assets/bikes/Avenis-125cc.jpg",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "4,199",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "6,999",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "8,399",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "9,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 57,
    name: "Honda Activa 125cc",
    image: "assets/bikes/activa-125-right-side-view.webp",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "4,199",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "6,999",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "8,399",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "9,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 8,
    name: "Hero Passion Pro - 110cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/hero-select-model-glaze-black-1622717908374.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "599", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "3,499",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "5,599",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "7,350",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "8,399",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 7,
    name: "Hero Glamour 125cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/hero-glomer.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "699", kms: "120 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "4,199",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "6,999",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "8,399",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "9,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 9,
    name: "Honda Shine 125cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Honda-Shine-125cc.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 749, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "5,249",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "9,799",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "12,599",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "13,999",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 99,
    name: "Honda SP Shine 125cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Honda-SP-Shine-125cc-sp.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 749, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "5,249",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "9,799",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "12,599",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "13,999",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 10,
    name: "Bajaj Pulsar 150cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Bajaj_Pulsar-standard.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 799, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "5,249",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "9,799",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "12,599",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "13,999",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 10,
    name: "Yamaha FZX 150cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Yamaha-FZX-150CC.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 899, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "6,299",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "11,199",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "14,699",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "16,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Bajaj Pulsar Ns 160cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Nss-160cc.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 999, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "6,299",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "11,199",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "14,699",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "16,799",
        kms: "2,000 Kms",
      },
    ],
  },

  {
    id: 11,
    name: "Honda Unicorn 160cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Honda-Unicorn-160cc.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 999, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "6,299",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "11,199",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "14,699",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "16,799",
        kms: "2,000 Kms",
      },
    ],
  },

  {
    id: 11,
    name: "Honda Hornet 200cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Honda-Hornet-200cc.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 999, kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "6,299",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "11,199",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "14,699",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "16,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Bajaj Avenger Cruise 220cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Bajaj-Avenger-Cruise-220cc.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "1,099", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "6,299",
        kms: "700 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "11,199",
        kms: "1,000 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "14,699",
        kms: "1,500 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "16,799",
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Hero Xpulse 2V 200cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/hero-select-model-panther-black-1622717113278.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "1,199", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "7,770",
        kms: "1000 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "14,000",
        kms: "1,500 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "18,900",
        kms: "2,000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "22,499",
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 12,
    name: "Royal Enfield Classic 350cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/royal-enfield.jpeg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "1,199", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "7,770",
        kms: "1000 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "14,000",
        kms: "1,500 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "18,900",
        kms: "2,000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "22,499",
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 12,
    name: "Royal Enfield Classic 350cc BS6 (Dual Seat Available)",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/re-classic.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "1,299", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "7,770",
        kms: "1000 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "14,000",
        kms: "1,500 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "18,900",
        kms: "2,000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "22,499",
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Hero Xpulse 4V 200cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/Hero-Xpulse-200cc.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "1,399", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "8,399",
        kms: "1000 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "14,000",
        kms: "1,500 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "18,900",
        kms: "2,000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "22,499",
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 12,
    name: "Royal Enfield Hunter 350cc",
    exceed: 'Kilometors limit if exceeds Rs.5/KM',
    image: "assets/bikes/hunter_rebel_red_000.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: "1,499", kms: "150 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: "9,100",
        kms: "1000 Kms",
      },
      {
        id: 3,
        duration: "2 Week",
        price: "16,800",
        kms: "1,500 Kms",
      },
      {
        id: 4,
        duration: "3 Week",
        price: "21,000",
        kms: "2,000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: "25,200",
        kms: "2,500 Kms",
      },
    ],
  },
]

