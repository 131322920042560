import ExploreContainer from "../components/ExploreContainer";
import Header from "../components/Layout";
import "./Call.css";

const Privacy: React.FC = () => {
    return (
        <Header>
            <ExploreContainer>
                <br />
                <div style={{width: "70%", margin:"auto 40px"}}>
                    <h1>Mrbikes Rental Policy</h1>

                    <h2>Eligibility to Rent</h2>
                    <p>To rent a vehicle from Mrbikes, you must be:</p>
                    <ul>
                        <li>21 years old or older</li>
                        <li>Hold a valid Indian driving license (learner's license not accepted)</li>
                    </ul>

                    <h2>Documents Required</h2>
                    <h3>Indian Users</h3>
                    <ul>
                        <li>Original Indian Driving License</li>
                        <li>Original Aadhar Card (if mobile number is not updated, upload PAN or Passport)</li>
                    </ul>
                    <h3>International Users</h3>
                    <ul>
                        <li>Valid driving license from home country</li>
                        <li>International Driving Permit</li>
                        <li>Valid Visa and Passport</li>
                    </ul>
                    <p><b>**All documents must be originals and presented at pickup. Bookings may be denied without proper documentation, and no refunds will be issued.**</b></p>

                    <h2>Fuel Policy</h2>
                    <p>Fuel is not included in the rental price. Mrbikes provides enough fuel to reach the nearest station. No refunds for leftover fuel upon return.</p>

                    <h2>Vehicle Maintenance</h2>
                    <p>The renter is responsible for checking engine oil and maintaining the vehicle during the rental period. Report any mechanical failures immediately. You may be held responsible for damages caused by neglecting routine maintenance.</p>

                    <h2>Driving Rules and Regulations</h2>
                    <p>You are responsible for following all traffic rules. Mrbikes is not liable for any fines or consequences resulting from your driving.</p>
                    <ul>
                        <li>Do not drive under the influence of alcohol or drugs. You are liable for all damages in such cases.</li>
                        <li>Maintain speed limits (60 km/h for scooters & motorbikes, 80 km/h otherwise). Fines apply for exceeding limits (200 INR each time).</li>
                        <li>Always wear a helmet while riding.</li>
                        <li>No racing or stunts (wheelie, stoppie, burnout) with our vehicles. Penalties apply (25,000 INR for racing, 35,000 INR for stunts).</li>
                        <li>Scooters are not allowed for outstation trips.</li>
                    </ul>

                    <h2>Cancellation Policy</h2>
                    <ul>
                        <li>25% deduction for cancellations 4 days before pickup.</li>
                        <li>50% refund for cancellations between 1 and 4 days before pickup.</li>
                        <li>No refunds for cancellations within 1 day of pickup or after pickup time.</li>
                        <li>No refunds for cash on pickup bookings.</li>
                        <li>100% refund if Mrbikes cannot provide the bike due to unforeseen circumstances or vehicle problems.</li>
                    </ul>

                    <h2>Misbehavior with Support Representatives</h2>
                    <p>Mrbikes is committed to a respectful environment. We will not tolerate rudeness or harassment towards our support staff. Accounts involved in such behavior will be deactivated and blocked.</p>

                    <h2>Availability of Bikes</h2>
                    <p>All bookings are subject to bike availability. Mrbikes will offer a full refund if we cannot fulfill your booking due to unforeseen circumstances.</p>

                    <h2>Vehicle Return Policy</h2>
                    <ul>
                        <li>Return the vehicle at the designated time and location specified in your booking/invoice, in fully serviceable condition.</li>
                        <li>A 30-minute grace period applies after the trip end time (except for hourly rentals, where late returns are not accepted).</li>
                        <li>Late returns after the grace period incur a fee of 1000 INR plus double the hourly rate.</li>
                        <li>Return the vehicle to the same location from where you picked it up.</li>
                        <li>Do not abandon the vehicle without written permission from Mrbikes. A fine up to the full security deposit will be imposed, and legal action may be taken.</li>
                        <li>Do not allow unauthorized riders to operate the vehicle during your rental period.</li>
                        <li>Smoking is strictly prohibited in Mrbikes vehicles. A fine of 500 INR plus the cost of replacing any damaged spare parts or accessories will be charged if evidence of smoking is found.</li>
                        <li>Return the vehicle in a clean condition. An additional cleaning fee of up to 200 INR applies for dirty returns.</li>
                        <li>No refunds for early returns of the vehicle.</li>
                    </ul>
                </div>
            </ExploreContainer>
        </Header>
    );
};

export default Privacy;
