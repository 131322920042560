import {
  IonContent,
  IonPage,
} from "@ionic/react";
import Footer from "./Footer";
import "./Layout.css";

const Layout: React.FC = ({ children }) => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          className="ionic-header"
          style={isMobileDevice ? {backgroundPosition: "-200px 20px", height: '50vh' } : { height: "70vh" }}
        >
          <img
            className="mr-bikes-logo"
            alt="mr bikes"
            src="assets/logo/mr_bikes_logo.png"
          />
        </div>
        {children}
        <iframe
          width={'100%'}
          height={500}
          title="Mr bikes location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.4482491458116!2d83.28781807862548!3d17.72350623996153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39436c2db55223%3A0xdb817ce78c430e20!2sMr%20Bike%20Rentals%20Vizag!5e0!3m2!1sen!2sin!4v1728218551565!5m2!1sen!2sin"
        ></iframe>
        <div className="caption">
          <h1>Keywords</h1>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around", width: "80%", overflow: "scroll", margin: "auto" }}>

          <ul style={{ marginTop: 0 }}>
            <li>Bike rental Vizag</li>
            <li>Bike rentals Vizag</li>
            <li>Rent bike Vizag</li>
            <li>Rent a bike Vizag</li>
            <li>Rental bikes Vizag</li>
            <li>Best bike rentals Vizag</li>
            <li>Two wheeler rentals Vizag</li>
            <li>Cheap bike rentals Vizag</li>
            <li>Bike hire Vizag</li>
            <li>Bike on hire Vizag</li>
            <li>Mr Bike rentals Vizag</li>
          </ul>
          <ul style={{ marginTop: 0 }}>
            <li>Bike rentals near railway station Vizag</li>
            <li>Bike rentals near Visakhapatnam railway station</li>
            <li>Bike rental Visakhapatnam</li>
            <li>Bike rentals Visakhapatnam</li>
            <li>Rent bike Visakhapatnam</li>
            <li>Rent a bike Visakhapatnam</li>
            <li>Rental bikes Visakhapatnam</li>
            <li>Best bike rentals Visakhapatnam</li>
            <li>Two wheeler rentals Visakhapatnam</li>
            <li>Cheap bike rentals Visakhapatnam</li>
            <li>Mr Bike rentals Visakhapatnam</li>
            <li>Bike rentals near railway station Visakhapatnam</li>

          </ul>
          <ul style={{ marginTop: 0 }}>
            <li>Scooty for Rent in Vizag</li>
            <li>Rent a Scooty in Vizag</li>
            <li>Hire Bike in Visakhapatnam</li>
            <li>Bike Rental</li>
            <li>Bike Rentals in Vizag</li>
            <li>Two Wheelers for rent</li>
            <li>Two wheelers for Hire</li>
            <li>Motor bike on rent</li>
            <li>Rent a Motor bike</li>
            <li>Scooter on Rent</li>
            <li>Rental Scooter</li>
          </ul>
          <ul style={{ marginTop: 0 }}>
            <li>Bike rentals in Maduravada</li>
            <li>Bike rentals in Vijayanagaram</li>
            <li>Bike rentals in Aruku</li>
            <li>Scooty rentals in Vizag</li>
            <li>Honda dirt bike rentals in Vizag</li>
            <li>Royal Enfield bike rentals in Vizag</li>
            <li>Honda Niva rentals in Vizag</li>
            <li>HeroHonda rentals in Vizag</li>
            <li>Honda Shine rentals in Vizag</li>
            <li>Pulsar rentals in Vizag</li>
            <li>Duke rentals in Vizag</li>
          </ul>
          <ul style={{ marginTop: 0 }}>
            <li>Fz rentals in Vizag</li>
            <li>R15 rentals in Vizag</li>
            <li>Apache Bikes rentals in Vizag</li>
            <li>Wisp bike rentals in Vizag</li>
            <li>Honda Activa 4G rentals in Vizag</li>
            <li>Bike Rides</li>
            <li>Yamaha Fazer rentals in Vizag</li>
            <li>TVS Jupiter rentals in Vizag</li>

            <li>Hire Scooter</li>
            <li>Mrbikes in Vizag</li>
            <li>Motorcycle for Rent</li>
            <li>Bike rentals in Gajuwaka</li>
            <li>Bike rentals near Visakhapatnam railway station</li>
            <li>Activa on rent in Vizag</li>
            <li>Sports bike on rent in Vizag</li>
            <li>Bike rentals in Vizag near to airport</li>
            <li>Vizag bike rentals</li>
            <li>Bike for rent</li>
            <li>Rent for bike</li>
          </ul>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Layout;
