import { IonButton } from "@ionic/react";
import { useEffect } from "react";
import ExploreContainer from "../components/ExploreContainer";
import Header from "../components/Layout";
import "./WhatsApp.css";

const WhatsApp: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://wa.me/919155405060?text=Hi%20Mr%20Bikes";
  }, []);
  return (
    <Header>
      <ExploreContainer>
      <br />
        <br />
        <br />
        <br />
        <br />
        <IonButton routerLink="/ping-me-now" color="primary">
          Book a bike via WhatsApp
        </IonButton>
        <br />
        <br />
        <br />
        <br />
        <br />
      </ExploreContainer>
    </Header>
  );
};

export default WhatsApp;
