import ExploreContainer from "../components/ExploreContainer";
import Header from "../components/Layout";
import "./About.css";

const About: React.FC = () => {
  return (
    <Header>
      <ExploreContainer>
        <div style={{ width: "70%", margin: "auto 40px" }}>
          <br />
          <br />
          <p>Welcome to Mr Bike Rentals Vizag, your ultimate destination for all your biking needs in Visakhapatnam! we are located near Vizag Railway Station, we offer a wide range of high-quality bikes and scooters for rent at affordable rates. If you're searching for bike rentals in Vizag, or bike hire in Vizag, Mr Bike Rentals Vizag is your go-to destination. Whether you're a tourist exploring the beautiful city of Vizag or a local resident in need of a reliable mode of transportation,  Our extensive fleet includes a variety of bikes to suit your preferences and budget. we pride ourselves on providing exceptional customer service and ensuring a hassle-free rental experience. Contact us today to reserve your bike and embark on your Vizag adventure!</p>
          <br />
          <br />
        </div>
      </ExploreContainer>
    </Header>
  );
};

export default About;
