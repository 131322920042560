import Bikes from "../components/Bikes";
import ExploreContainer from "../components/ExploreContainer";
import Header from "../components/Layout";
import "./Home.css";

const Home: React.FC = () => {
  return (
    <Header>
        <ExploreContainer>
            <Bikes />
        </ExploreContainer>
    </Header>
  );
};

export default Home;
