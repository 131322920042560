import { IonButton } from "@ionic/react";
import { useEffect } from "react";
import ExploreContainer from "../components/ExploreContainer";
import Header from "../components/Layout";
import "./Call.css";

const Call: React.FC = () => {
  useEffect(() => {
    window.location.href = "tel:+919155405060";
  });
  return (
    <Header>
      <ExploreContainer>
        <br />
        <br />
        <br />
        <br />
        <br />
        <IonButton routerLink="/call-me-now" color="primary">
          Call to Book a Bike
        </IonButton>
        <br />
        <br />
        <br />
        <br />
        <br />
      </ExploreContainer>
    </Header>
  );
};

export default Call;
